"use client";
import { config } from "../config";
import { Address, erc20Abi } from "viem";
import { MBTokenList } from "../../constants/contractAddresses";
import { useReadContracts } from "wagmi";
import useWalletAccount from "./useWalletAccount";
import { useEffect, useState } from "react";
import { userBalanceProp } from "../../types/types";
import { useFetchGatewayNativeTokenAddress } from "./useFetchGatewayNativeTokenAddress";
// import gatewayAbi from "../abis/gatewayAbi";
import gatewaySwappableAmount from "../abis/gatewaySwappableAmount";

export const useFetchOFTBalances = () => {
  const [userOftBalances, setBalances] = useState<userBalanceProp[] | null>();
  const tokenContracts = [];
  const gatewayContractsBalance = [];
  const gatewayContracts = [];
  const { account } = useWalletAccount();
  const [isBalanceFetched, setIsBalanceFetched] = useState<boolean>(false);
  const { tokensWithNativeAddress } = useFetchGatewayNativeTokenAddress();
  for (let i = 0; i < MBTokenList.length; i++) {
    const token = MBTokenList[i];
    tokenContracts.push({
      abi: erc20Abi,
      address: token.tokenAddress as Address,
      functionName: "balanceOf",
      args: [account],
      chainId: token.chainId,
    });
  }

  for (let i = 0; i < MBTokenList.length; i++) {
    const token = MBTokenList[i];
    gatewayContractsBalance.push({
      abi: erc20Abi,
      address: token.tokenAddress as Address,
      functionName: "balanceOf",
      args: [token.gateWayAddress],
      chainId: token.chainId,
    });
  }

  for (let i = 0; i < MBTokenList.length; i++) {
    const token = MBTokenList[i];
    gatewayContracts.push({
      abi: gatewaySwappableAmount,
      address: token.gateWayAddress as Address,
      functionName: "swappableAmount",
      chainId: token.chainId,
    });
  }

  const {
    data: gatewaySwappableAmountData,
    refetch: refetchGatewaySwappableAmount,
  } = useReadContracts({
    config,
    contracts: gatewayContracts,
  });

  const { data: gatewaybalanceData, refetch: refetchGatewayOftBalance } =
    useReadContracts({
      config,
      contracts: tokensWithNativeAddress,
    });

  const {
    data: balanceData,
    refetch: refetchOftBalance,
    isFetching: isFetchingOftBalance,
    isFetched: isOftBalanceFetched,
  } = useReadContracts({
    config,
    contracts: tokenContracts,
  });

  useEffect(() => {
    if (account) {
      refetchOftBalance();
      refetchGatewayOftBalance();
      refetchGatewaySwappableAmount();
    }
  }, [
    account,
    refetchOftBalance,
    refetchGatewayOftBalance,
    refetchGatewaySwappableAmount,
  ]);

  useEffect(() => {
    if (
      !balanceData ||
      !gatewaybalanceData ||
      !tokensWithNativeAddress ||
      !gatewaySwappableAmountData
    )
      return;
    const updatedBalance = MBTokenList.map((token, index) => ({
      ...token,
      userTokenBalance: balanceData[index]?.result as bigint | undefined,
      gatewayBalance: gatewaybalanceData[index]?.result as bigint | undefined,
      swappableAmount: gatewaySwappableAmountData[index]?.result as
        | bigint
        | undefined,
    }));
    setIsBalanceFetched(true);
    setBalances(updatedBalance);
  }, [
    balanceData,
    gatewaybalanceData,
    tokensWithNativeAddress,
    gatewaySwappableAmountData,
  ]);

  return {
    userOftBalances,
    isBalanceFetched,
    refetchOftBalance,
    isFetchingOftBalance,
    isOftBalanceFetched,
  };
};
