"use client";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useFetchRealTokenBalances } from "@/web3/hooks/useFetchTokenBalances";
import { TokenProp, userBalanceProp } from "@/types/types";
import useWalletAccount from "@/web3/hooks/useWalletAccount";
import { useSwitchChain } from "wagmi";
import { Address, Chain, erc20Abi } from "viem";
import useUserAllowance from "@/web3/hooks/useUserAllowance";
import { fromWei, toWei } from "@/utils/web3BigNumbers";
import { config } from "@/web3/config";
import { writeContract } from "@wagmi/core";
import { BridgeAddresses } from "@/constants/contractAddresses";
import bridgeAbi from "@/web3/abis/bridgeAbi";
import { readContract, waitForTransactionReceipt } from "wagmi/actions";
import { ChainEIds } from "@/web3/chains";
import gatewayAbi from "@/web3/abis/gatewayAbi";
import { useFetchOFTBalances } from "@/web3/hooks/useFetchOFTBalances";

export const BridgeContext = createContext<{
  tokensBalance: userBalanceProp[] | null;
  handleSelectTokenToBridge: (token: TokenProp | null) => void;
  selectedTokenToBridge: TokenProp | null;
  selectedTokenBalance: string | null;
  bridgeAmount: string | null;
  handleChangeBridgeAmount: (amount: string) => void;
  insufficientBalance: boolean;
  selectedTokenName: string;
  setSelectedTokenName: (tokenName: string) => void;
  destinationChain: Chain | null;
  setDestinationChain: (chain: Chain | null) => void;
  handleReset: () => void;
  userAllowance: string;
  setUserAllowance: (userAllowance: string) => void;
  handleApprove: () => void;
  handleBridge: () => void;
  setMaxAmountToBridge: () => void;
  bridgeHash: Address | null;
  status: string | null;
  setStatus: (value: string | null) => void;
  setBridgeHash: (value: Address | null) => void;
  desTxHash: string | null;
  setDesTxHash: (hash: Address | null) => void;
  isTxModalOpen: boolean;
  setIsTxModalOpen: (isOpen: boolean) => void;
  handleSwapToReal: (token: userBalanceProp) => void;
  selectedTokenForSwap: userBalanceProp | null;
  setSelectedTokenForSwap: (token: userBalanceProp | null) => void;
  isDelivered: boolean;
  setIsDelivered: (item: boolean) => void;
  swappableAmount: any;
  setSwappableAmount: React.Dispatch<React.SetStateAction<any>>;
}>({
  tokensBalance: null,
  handleSelectTokenToBridge: () => {},
  selectedTokenToBridge: null,
  selectedTokenBalance: null,
  bridgeAmount: null,
  handleChangeBridgeAmount: () => {},
  insufficientBalance: false,
  selectedTokenName: "",
  setSelectedTokenName: () => {},
  destinationChain: null,
  setDestinationChain: () => {},
  handleReset: () => {},
  userAllowance: "",
  setUserAllowance: () => {},
  handleApprove: () => {},
  handleBridge: () => {},
  setMaxAmountToBridge: () => {},
  bridgeHash: null,
  status: null,
  setStatus: () => {},
  setBridgeHash: () => {},
  desTxHash: null,
  setDesTxHash: () => {},
  isTxModalOpen: false,
  setIsTxModalOpen: () => {},
  handleSwapToReal: () => {},
  selectedTokenForSwap: null,
  setSelectedTokenForSwap: () => {},
  isDelivered: false,
  setIsDelivered: () => {},
  swappableAmount: null,
  setSwappableAmount: () => {},
});

export const BridgeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { switchChain } = useSwitchChain();
  const { userTokenBalances, refetchTokenBalance } =
    useFetchRealTokenBalances();

  const { refetchOftBalance } = useFetchOFTBalances();

  const { connectedChainId, isConnected, account, connectedChain } =
    useWalletAccount();
  const { handleFetchAllowance } = useUserAllowance();

  const [swappableAmount, setSwappableAmount] = useState<any>();

  const [isTxModalOpen, setIsTxModalOpen] = useState<boolean>(false);

  const [bridgeHash, setBridgeHash] = useState<Address | null>(null);

  useEffect(() => {
    const hash = localStorage.getItem("bridgeHash") as Address | null;
    if (hash) {
      setBridgeHash(hash);
    }
  }, []);

  const [status, setStatus] = useState<string | null>(null);

  const [desTxHash, setDesTxHash] = useState<Address | null>(null);

  const [isDelivered, setIsDelivered] = useState<boolean>(false);

  const extraOptions =
    "0x000301001101000000000000000000000000000f4240" as Address;

  const [tokensBalance, setUserTokensBalance] = useState<
    null | userBalanceProp[]
  >(null);

  const [selectedTokenBalance, setSelectedTokenBalance] = useState<
    string | null
  >(null);

  const [selectedTokenToBridge, setSelectedTokenToBridge] =
    useState<TokenProp | null>(null);

  const [bridgeAmount, setBridgeAmount] = useState<string | null>(null);

  const [insufficientBalance, setInsufficientBalance] =
    useState<boolean>(false);

  const [selectedTokenName, setSelectedTokenName] = useState<string>("");

  const [destinationChain, setDestinationChain] = useState<Chain | null>(null);

  const [userAllowance, setUserAllowance] = useState<string>("");

  const [selectedTokenForSwap, setSelectedTokenForSwap] =
    useState<userBalanceProp | null>(null);

  const handleSelectTokenToBridge = (token: TokenProp | null) => {
    setSelectedTokenToBridge(token);
    handleFetchAllowance(token, setUserAllowance);
  };

  const handleChangeBridgeAmount = (amount: string) => {
    setBridgeAmount(amount);
    setInsufficientBalance(
      Number(amount) > Number(selectedTokenBalance) ||
        Number(amount) > Number(swappableAmount)
    );
  };

  useEffect(() => {
    if (userTokenBalances) {
      setUserTokensBalance(userTokenBalances);
    }
  }, [userTokenBalances]);

  // const handleRefetch = async () => {
  //   await refetchOftBalance();
  // };

  // useEffect(() => {
  //   handleRefetch();
  // }, [selectedTokenToBridge]);

  useEffect(() => {
    if (!isConnected) setDestinationChain(null);
  }, [isConnected]);

  useEffect(() => {
    if (!selectedTokenToBridge) return;
    if (tokensBalance) {
      const balance = tokensBalance
        .find(
          (token) =>
            token.tokenAddress.toLowerCase() ===
              selectedTokenToBridge.tokenAddress.toLowerCase() &&
            selectedTokenToBridge.chainId === token.chainId
        )
        ?.userTokenBalance?.toString();

      setSelectedTokenBalance(balance ? fromWei(balance) : " 0");

      if (bridgeAmount && balance) {
        setInsufficientBalance(Number(bridgeAmount) > Number(balance));
      }
    }
    if (selectedTokenToBridge.chainId !== connectedChainId) {
      switchChain({ chainId: selectedTokenToBridge.chainId });
    }
  }, [selectedTokenToBridge, tokensBalance, connectedChainId]);

  useEffect(() => {
    if (
      connectedChainId &&
      selectedTokenToBridge &&
      selectedTokenToBridge.chainId !== connectedChainId
    ) {
      handleReset();
    }
  }, [connectedChainId]);

  const handleReset = () => {
    setSelectedTokenName("");
    setSelectedTokenBalance(null);
    setSelectedTokenToBridge(null);
    setInsufficientBalance(false);
    setBridgeAmount(null);
    refetchOftBalance();
  };

  const handleApprove = async () => {
    if (!selectedTokenToBridge || !destinationChain || !bridgeAmount) {
      return null;
    }
    if (selectedTokenToBridge.chainId !== connectedChainId) return;
    const amount = toWei(bridgeAmount!, selectedTokenToBridge!.decimals);
    const result = await writeContract(config, {
      abi: erc20Abi,
      address: selectedTokenToBridge?.tokenAddress as Address,
      functionName: "approve",
      args: [
        BridgeAddresses[selectedTokenToBridge!.chainId] as Address,
        BigInt(amount!.toString()),
      ],
    });
    await waitForTransactionReceipt(config, { hash: result });
    handleFetchAllowance(selectedTokenToBridge, setUserAllowance);
  };

  const handleGetQuoteSend = async () => {
    if (!selectedTokenToBridge || !destinationChain || !bridgeAmount) {
      return null;
    }

    if (selectedTokenToBridge.chainId !== connectedChainId) return;

    const result = await readContract(config, {
      abi: bridgeAbi,
      address: BridgeAddresses[selectedTokenToBridge.chainId] as Address,
      functionName: "quoteSend",
      args: [
        selectedTokenToBridge!.tokenAddress as Address,
        account as Address,
        ChainEIds[destinationChain.id],
        BigInt(toWei(bridgeAmount).toString()),
        BigInt(toWei(bridgeAmount).toString()),
        extraOptions,
        false,
      ],
      account,
      chainId: selectedTokenToBridge.chainId,
    });

    console.log("quoteSent result:", result);
    return result;
  };

  const handleBridge: any = async () => {
    if (!bridgeAmount || !selectedTokenToBridge || !destinationChain)
      return null;

    console.log(bridgeAmount);
    if (selectedTokenToBridge.chainId !== connectedChainId) return;
    const res = await handleGetQuoteSend();
    if (!res) return;

    const nativeFee: {
      nativeFee: bigint;
      lzTokenFee: bigint;
      bridgeFee: bigint;
    } = {
      nativeFee: res[0],
      lzTokenFee: res[1],
      bridgeFee: res[2],
    };

    const totalFee = nativeFee.nativeFee + nativeFee.bridgeFee;

    console.log("total fee:", totalFee);
    const amount = toWei(bridgeAmount!);
    const result = await writeContract(config, {
      abi: bridgeAbi,
      address: BridgeAddresses[selectedTokenToBridge.chainId] as Address,
      functionName: "send",
      args: [
        selectedTokenToBridge.tokenAddress as Address,
        ChainEIds[destinationChain.id],
        BigInt(amount.toString()),
        BigInt(amount.toString()),
        extraOptions,
        nativeFee,
      ],
      account,
      value: totalFee,
      chainId: selectedTokenToBridge.chainId,
      chain: connectedChain,
    });

    await waitForTransactionReceipt(config, { hash: result });
    setBridgeHash(result);
    localStorage.setItem("bridgeHash", result);
    setIsTxModalOpen(true);
    await refetchTokenBalance();
    await refetchOftBalance();
    handleFetchAllowance(selectedTokenToBridge, setUserAllowance);
    return result;
  };

  const setMaxAmountToBridge = () => {
    if (!selectedTokenToBridge || !selectedTokenBalance) return;

    setInsufficientBalance(false);

    const maxAmount =
      Number(selectedTokenBalance) > Number(swappableAmount)
        ? swappableAmount
        : selectedTokenBalance;

    const formattedMaxAmount = maxAmount.toString().includes(".")
      ? maxAmount.toString().split(".")[0] +
        "." +
        maxAmount.toString().split(".")[1].slice(0, 6)
      : maxAmount;

    setBridgeAmount(formattedMaxAmount);
  };

  const handleSwapToReal = async (token: userBalanceProp) => {
    try {
      const amount = token.swappableAmount
        ? token.swappableAmount
        : token.gatewayBalance;
      const result = await writeContract(config, {
        abi: gatewayAbi,
        address: token.gateWayAddress as Address,
        functionName: "swapToNative",
        args: [
          token.userTokenBalance! > amount! ? amount! : token.userTokenBalance!,
        ],
        account,
        chainId: token.chainId,
        chain: connectedChain,
      });
      await waitForTransactionReceipt(config, { hash: result });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <BridgeContext.Provider
      value={{
        tokensBalance,
        handleSelectTokenToBridge,
        selectedTokenToBridge,
        selectedTokenBalance,
        bridgeAmount,
        handleChangeBridgeAmount,
        insufficientBalance,
        selectedTokenName,
        setSelectedTokenName,
        destinationChain,
        setDestinationChain,
        handleReset,
        userAllowance,
        setUserAllowance,
        handleApprove,
        handleBridge,
        setMaxAmountToBridge,
        bridgeHash,
        status,
        setStatus,
        setBridgeHash,
        desTxHash,
        setDesTxHash,
        isTxModalOpen,
        setIsTxModalOpen,
        handleSwapToReal,
        selectedTokenForSwap,
        setSelectedTokenForSwap,
        isDelivered,
        setIsDelivered,
        setSwappableAmount,
        swappableAmount,
      }}
    >
      {children}
    </BridgeContext.Provider>
  );
};

export const useBridgeContext = () => useContext(BridgeContext);
