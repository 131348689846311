import { W3bNumber } from "@/types/types";
import { BigNumber } from "bignumber.js";
import { ethers } from "ethers";

export function toBN(num: BigNumber.Value): BigNumber {
  return new BigNumber(num);
}

export const BN_ZERO: BigNumber = toBN("0");

export const fromWei = (amount: BigNumber.Value, decimals = 18) => {
  const bnAmount = toBN(amount);
  if (bnAmount.isZero()) return "0";
  return bnAmount.dividedBy(toBN(10).pow(decimals)).toString();
};

export const toWei = (amount: BigNumber.Value, decimals = 18) => {
  const bnAmount = toBN(amount);
  if (bnAmount.isZero()) return 0;
  return bnAmount.multipliedBy(toBN(10).pow(decimals));
};

export const formatNumber = (num: number) => {
  let fixedNumber = num.toFixed(3);

  let floatNumber = parseFloat(fixedNumber);

  if (floatNumber !== num) {
    return fixedNumber;
  } else {
    return num.toString();
  }
};

export function w3bNumberFromNumber(amount: number, decimals = 18): W3bNumber {
  const inputAsWei = ethers.utils.parseUnits(amount.toString(), decimals);
  const inputAsBigInt = BigInt(inputAsWei.toString());
  const inputAsString = amount.toString();
  const inputAsNumber = parseFloat(parseFloat(amount.toString()).toFixed(2));

  return {
    dsp: inputAsNumber,
    hStr: inputAsString,
    big: inputAsBigInt,
    bigStr: inputAsBigInt.toString(),
  };
}

export function w3bNumberFromString(amount: string, decimals = 18): W3bNumber {
  if (!amount)
    return { dsp: 0, hStr: "", big: BigInt(0), bigStr: BigInt(0).toString() };

  const inputAsWei = ethers.utils.parseUnits(amount, decimals);
  const inputAsBigInt = BigInt(inputAsWei.toString());
  const inputAsString = amount;
  const inputAsNumber = Math.floor(parseFloat(amount));

  return {
    dsp: inputAsNumber,
    hStr: inputAsString,
    big: inputAsBigInt,
    bigStr: inputAsBigInt.toString(),
  };
}
